body {
    overflow-x: hidden;
}

@font-face {
  font-family: "Moul Light";
  src: local("Khmer OS Muol Light Regular"),
      url("./fonts/Khmer OS Muol Light Regular.ttf");
}

@font-face {
  font-family: "Baphnom";
  src: local("Kh Baphnom 026 Theany"),
      url("./fonts/Kh Baphnom 026 Theany.ttf")
}

@font-face {
  font-family: "industry";
  src: local("Industry Black"),
      url("./fonts/Industry Black.otf")
}

@font-face {
  font-family: "Metal Chrieng";
  src: local("Metal Chrieng"),
      url("./fonts/Kh-Metal-Chrieng.ttf")
}

@font-face {
  font-family: "Taprom";
  src: local("Taprom"),
      url("./fonts/Taprom.ttf")
}

@font-face {
  font-family: "bodoni-bold-bt";
  src: local("Bodoni Bd BT"),
      url("./fonts/bodoni-bold-bt.ttf")
}

@font-face {
  font-family: "georgiapro-condblack";
  src: local("GeorgiaPro-CondBlack"),
      url("./fonts/GeorgiaPro-CondBlack.ttf")
}

bg-container {
  background-color: #EDDBCD;
}

#only-print {
    position: fixed;
    top:0;
    z-index: -1000;
}
#no-print {
    z-index: 100;
}

@page {
  size: A4;
  margin: 5mm 5mm 0mm 5mm;
}

@media print {
  body {
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
  }

  table{
      page-break-inside: avoid;
  }

  #only-print {
      display: inline-block !important;
      transition: none !important;
  }

  #no-print {
      display: none;
      transition: none !important;
  }
}
